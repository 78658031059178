import {
  Button,
  Divider,
  HorizontalStack,
  Icon,
  LegacyStack,
  Text,
  TextField,
  VerticalStack,
} from "@shopify/polaris";
import { EditMinor } from "@shopify/polaris-icons";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { changeCustomerBirthday } from "@vendor-app/app/_state/actionCreators/customers";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import {
  getAgeFromBirthdayString,
  getMonthFirstFormattedDate,
  isValidDate,
} from "@vendor-app/utils/dateFormatters";
import React, { useCallback, useMemo, useState } from "react";

export const formatBirthdayWithIso = (date?: Date) =>
  date
    ? toViewDate(ISODateString.fromString(date).toJSDate(), {
        year: "numeric",
        day: "2-digit",
        month: "long",
      })
    : "";
export const BirthdaySection = ({ customer }: { customer: ICustomerRelationshipShallow }) => {
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();
  const [isEditingBirthday, setIsEditingBirthday] = useState(false);
  const [hasDateError, setHasDateError] = useState<boolean>(false);
  const [customerBirthdayString, setCustomerBirthdayString] = useState<string>(
    getMonthFirstFormattedDate(customer.birthdayDate)
  );

  const changeBirthday = useCallback(() => {
    if (!isValidDate(customerBirthdayString)) {
      setHasDateError(true);
      return;
    }
    setHasDateError(false);

    let date;
    if (customerBirthdayString) {
      const [month, day, year] = customerBirthdayString.split("/");
      date = new Date(+year, +month - 1, +day);
    }
    try {
      setIsEditingBirthday(false);
      dispatch(changeCustomerBirthday(customer.id, date ? date.toISOString() : null));
      if (customerBirthdayString) {
        addToast("Updated customer's birthday");
      } else {
        addToast("Removed customer's birthday");
      }
    } catch (error) {
      console.error(error);
      addToast("Error updating customer's birthday", true);
    }
  }, [customer.id, customerBirthdayString, addToast, dispatch]);

  const customersAge = useMemo(() => getAgeFromBirthdayString(customerBirthdayString), [customerBirthdayString]);

  const isoFormattedCustomersBirthday = useMemo(
    () => formatBirthdayWithIso(customer.birthdayDate),
    [customer.birthdayDate]
  );

  const cancelBirthdayEditing = useCallback(() => {
    setIsEditingBirthday(false);
    setCustomerBirthdayString(getMonthFirstFormattedDate(customer.birthdayDate));
  }, [customer.birthdayDate]);

  return (
    <VerticalStack gap={"3"}>
      <Text variant="headingSm" as="h2">
        Birthday
      </Text>
      {isEditingBirthday ? (
        <VerticalStack gap={"4"}>
          <TextField
            autoComplete="off"
            label="Enter customer's birthday"
            placeholder="MM/DD/YYYY"
            error={hasDateError ? "Please use format MM/DD/YYYY" : false}
            value={customerBirthdayString}
            onChange={setCustomerBirthdayString}
            maxLength={10}
          />
          <Divider borderWidth="1" />
          <HorizontalStack align="end" gap="2">
            <Button onClick={cancelBirthdayEditing}>Cancel</Button>
            <Button primary onClick={changeBirthday}>
              Confirm
            </Button>
          </HorizontalStack>
        </VerticalStack>
      ) : (
        <VerticalStack>
          <HorizontalStack align="space-between">
            {customer.birthdayDate ? (
              <LegacyStack vertical distribution="center" spacing="extraTight">
                <Text variant="bodyMd" as="p">
                  {isoFormattedCustomersBirthday}
                </Text>
                <Text variant="bodyMd" as="span" color="subdued">
                  {customersAge}
                </Text>
              </LegacyStack>
            ) : (
              <Text variant="bodyMd" as="span" color="subdued">
                No birthday on file
              </Text>
            )}
            <span onClick={() => setIsEditingBirthday(prev => !prev)} style={{ cursor: "pointer" }}>
              <Icon source={EditMinor} />
            </span>
          </HorizontalStack>
        </VerticalStack>
      )}
    </VerticalStack>
  );
};
