import React, { useCallback, useState } from "react";

import { createSmartrrModal } from "@vendor-app/utils/createModalStore";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { HorizontalStack, Modal, TextField, VerticalStack } from "@shopify/polaris";
import { changeCustomerAmountOfPoints } from "@vendor-app/app/_state/actionCreators/customers";

export const useUpdateLoyaltyPointsModalStore = createSmartrrModal<LoyaltyPointsModalProps>();

interface LoyaltyPointsModalProps {
  customer: ICustomerRelationshipShallow;
}

export const UpdateLoyaltyPointsModal = () => {
  const { modalPayload } = useUpdateLoyaltyPointsModalStore();
  if (!modalPayload?.customer) {
    return <React.Fragment />;
  }
  return <UpdateLoyaltyPointsModalInner customer={modalPayload.customer} />;
};

const UpdateLoyaltyPointsModalInner = ({ customer }: LoyaltyPointsModalProps) => {
  const [updateCustomerBalanceReasonText, setUpdateCustomerBalanceReasonText] = useState<string>("");
  const [newCustomerPoints, setNewCustomerPoints] = useState<number>(customer.orgPoints ?? 0);
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();
  const { isModalOpen, closeModal } = useUpdateLoyaltyPointsModalStore();

  const changeCustomerPoints = useCallback(async () => {
    const res = await dispatch(
      changeCustomerAmountOfPoints({
        customerRelationshipId: customer.id,
        orgPoints: newCustomerPoints - (customer.orgPoints ?? 0),
        reason: updateCustomerBalanceReasonText,
      })
    );
    setUpdateCustomerBalanceReasonText("");
    closeModal();
    if (res.type === "CHANGED_CUSTOMER_POINTS") {
      addToast("Balance updated");
      return;
    }
    if (res.type === "ERROR_CHANGING_CUSTOMER_POINTS") {
      addToast("Error updating balance", true);
    }
  }, [addToast, customer.id, customer.orgPoints, dispatch, newCustomerPoints, updateCustomerBalanceReasonText]);

  return (
    <Modal
      sectioned
      open={isModalOpen}
      title="Update balance"
      onClose={closeModal}
      primaryAction={{ content: "Save", onAction: changeCustomerPoints }}
      secondaryActions={[{ content: "Cancel", onAction: closeModal }]}
    >
      <VerticalStack gap={"5"}>
        <HorizontalStack gap={"4"}>
          <TextField
            autoComplete="off"
            helpText={`(Original balance: ${customer?.orgPoints ?? 0})`}
            label="Adjust by"
            inputMode="numeric"
            min={0}
            value={String(newCustomerPoints - (customer?.orgPoints ?? 0))}
            onChange={value =>
              setNewCustomerPoints((customer?.orgPoints ?? 0) + Number(isNaN(+value) ? 0 : value))
            }
          />
          <TextField
            autoComplete="off"
            label="New"
            inputMode="numeric"
            min={0}
            value={String(newCustomerPoints)}
            onChange={value => setNewCustomerPoints(+value)}
          />
        </HorizontalStack>
        <TextField
          autoComplete="off"
          label="Reason"
          placeholder="Optional"
          helpText="This won't be visible to the customer."
          inputMode="text"
          value={updateCustomerBalanceReasonText}
          onChange={value => setUpdateCustomerBalanceReasonText(value)}
        />
      </VerticalStack>
    </Modal>
  );
};
