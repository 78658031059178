import { TypedTableColumnType } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";

import { CustomerSubscriptionTableColumnKeyType } from "..";

export const customerSubscriptionColumns: TypedTableColumnType<CustomerSubscriptionTableColumnKeyType> = {
  shopifyId: {
    name: "Subscription ID",
    paginatedValue: "shopifyId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  upcomingOrderDate: {
    name: "Upcoming order",
    paginatedValue: "upcomingOrderDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  status: {
    name: "Status",
    paginatedValue: "status",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  estimatedTotal: {
    name: "Est. total",
    paginatedValue: "estimatedTotal",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  items: {
    name: "Items",
    paginatedValue: "items",
    filtering: true,
    sorting: false,
    disabled: false,
  },
  createdDate: {
    name: "Created date",
    paginatedValue: "createdDate",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  sellingPlanId: {
    name: "Subscription program",
    paginatedValue: "sellingPlanId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};
