import { Text } from "@shopify/polaris";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { extractShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";

import { IdContainer } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { navigateToCustomerDetails } from "@vendor-app/utils/navigateWithShopInQuery";

import { CustomersParsedDataType } from "../types";

export * from "./table-columns";
export * from "./components";
export * from "./tableUtils";
export * from "./activatorButton";

export const parseCustomersForIndexTable = (
  customers: ICustomerRelationshipShallow[]
): CustomersParsedDataType[] => {
  return customers.map(customer => ({
    id: customer.shopifyId,
    customerShopifyId: (
      <IdContainer onClick={() => navigateToCustomerDetails(extractShopifyId(customer.shopifyId)!, false)}>
        <Text variant="bodyMd" as="span" fontWeight="semibold">
          #{extractShopifyId(customer?.shopifyId ?? "")}
        </Text>
      </IdContainer>
    ),

    customerName: `${customer.firstName} ${customer.lastName}`,
    emailOrName: customer.email,
    totalSpent: formatMoney(customer.totalSpent, customer.customerCurrency),
    totalOrders: customer.orderCount,
  }));
};

export const customerTablesTabs = [
  {
    content: "Subscriptions",
    id: "customer-subscriptions-tab",
    accessibilityLabel: "Customer Subscriptions Tab",
    panelID: "customer-subscriptions-tab-panel",
  },
  {
    content: "Orders",
    id: "customer-orders-tab",
    accessibilityLabel: "Customer Orders Tab",
    panelID: "customer-orders-tab-panel",
  },
  {
    content: "Transactions",
    id: "customer-transactions-tab",
    accessibilityLabel: "Customer Transactions Tab",
    panelID: "customer-transactions-tab-panel",
  },
];
export const DEFAULT_ACTIVE_LANGUAGE = "en";

export const languageCountryMap = {
  en: "US",
  fr: "FR",
  es: "ES",
};
