import styled from "styled-components";
import { Card, HorizontalStack, Button, Text } from "@shopify/polaris";

export const EraseCustomerDataWrapper = styled.div``;

interface EraseCustomerDataProps {
  handleEraseCustomerDataClick: () => Promise<void>;
}

export const EraseCustomerData = ({ handleEraseCustomerDataClick }: EraseCustomerDataProps) => {
  return (
    <EraseCustomerDataWrapper id="smartrr-erase-customer-data">
      <Card padding={"5"}>
        <HorizontalStack gap={"2"}>
          <div style={{ color: "#D72C0D" }}>
            <Button
              id="smartrr-erase-all-customer-data-button"
              outline
              monochrome
              onClick={handleEraseCustomerDataClick}
            >
              Erase all customer data
            </Button>
          </div>
          <Text id="smartrr-erase-all-customer-data-text" variant="bodyMd" as="span" color="subdued">
            Permanently erases all customer data associated with Smartrr.
          </Text>
        </HorizontalStack>
      </Card>
    </EraseCustomerDataWrapper>
  );
};
