import { Button, ButtonGroup, Layout, LegacyCard, LegacyStack, useIndexResourceState } from "@shopify/polaris";
import { ExportMinor, ExternalMinor } from "@shopify/polaris-icons";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { extractShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import React, { useCallback, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { createAndDownloadCsv } from "@vendor-app/app/../utils/createAndDownloadCsv";
import { TablePage } from "@vendor-app/app/_sharedComponents/TablePageWrapper";
import { TableSearch } from "@vendor-app/app/_sharedComponents/TableSearch";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import { loadCustomers } from "@vendor-app/app/_state/actionCreators/customers";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { exportCustomersToCsvRows, parseCustomerForCSV } from "@vendor-app/utils/exportCustomersToCsvRows";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";
import { mapArrayEntityWithDelay } from "@vendor-app/utils/mapArrayEntityWithDelay";
import { navigateToCustomerDetails } from "@vendor-app/utils/navigateWithShopInQuery";
import { redirectToShopify } from "@vendor-app/utils/redirectToShopify";

import { CustomerDetails } from "./CustomerDetails";
import {
  CustomersTableColumnKeyType,
  HeaderContainer,
  ICustomerCSV,
  ValueSelectorForCustomersFilter,
  customerColumns,
  customerSearchOptions,
  parseCustomersForIndexTable,
} from "./libs";
import { ButtonBoldText } from "../../_sharedComponents/ButtonBold";
import { getQueryParamsForFilters } from "../../_sharedComponents/TableSearch/libs";
import { useToast } from "../../_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  TypedTableColumnValue,
  usePolarisTypedTable,
} from "../../_sharedComponents/TypedTable/usePolarisTypedTable";
import { useActiveOrganizationSelector } from "../../_state/reducers/organizations";
import { PaginationContainer } from "../AdminSubscriptionDetailsRoute/libs";

export function AdminCustomersRoute({ customerId }: { customerId?: string }): JSX.Element {
  const {
    data: customers,
    totalCount,
    isLoading,
    totalPages,
    pageSize,
  } = useSmartrrVendorSelector(state => state.customerRelationships);
  const activeOrg = useActiveOrganizationSelector();
  const { addToast } = useToast();
  const isMobile = useMediaQuery({
    maxWidth: 900,
  });

  const { Table, Pagination, Filter, Sorting, Columns } = usePolarisTypedTable<CustomersTableColumnKeyType>();
  const [tableProps, tableHandlers] = useTableHandlers("customerShopifyId", "ASC");
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    customers.map(customer => ({ id: String(customer.shopifyId) }))
  );

  const [customerColumnsState, setCustomerColumnsState] = useState(customerColumns);
  const [exportToCSVIsLoading, setExportToCSVIsLoading] = useState<boolean>(false);

  const handleOpenInNewTab = (ids: string[]) => {
    for (const id of ids) {
      navigateToCustomerDetails(extractShopifyId(id)!, true);
    }
    handleSelectionChange(SelectionType.All, false);
  };

  const handleViewInShopify = (ids: string[]) => {
    for (const id of ids) {
      redirectToShopify(activeOrg, "customers", id);
    }
    handleSelectionChange(SelectionType.All, false);
  };

  const exportToCsvWithLoadingState = useCallback(
    async (customers?: ICustomerRelationshipShallow[]) => {
      setExportToCSVIsLoading(true);
      try {
        let rows;

        if (customers?.length) {
          rows = await mapArrayEntityWithDelay<ICustomerRelationshipShallow, ICustomerCSV>({
            items: customers,
            chunkSize: 250,
            waitInterval: 500,
            filterFunction: parseCustomerForCSV,
          });
        } else {
          rows = await exportCustomersToCsvRows({
            filterIn: getQueryParamsForFilters(tableProps, tableProps?.filter)?.filterIn,
          });
        }

        if (rows) {
          createAndDownloadCsv(rows, "Customers");
        }
      } catch {
        addToast("Error exporting to CSV");
      } finally {
        setExportToCSVIsLoading(false);
      }
    },
    [tableProps]
  );

  const viewCustomersInShopify = (activeOrg: IOrganization | null) => {
    if (!activeOrg?.myShopifyDomain) {
      addToast("Can't open in Shopify");
      return;
    }
    frontEndTabOpen(`https://${activeOrg?.myShopifyDomain}/admin/customers`);
  };

  const handleExportAction = useCallback(
    async (customerIds: string[]) => {
      const selectedCustomers = customerIds.reduce<ICustomerRelationshipShallow[]>((acc, prev) => {
        const customer = customers.find(customer => customer.shopifyId === prev);
        if (customer) {
          acc.push(customer);
        }
        return acc;
      }, []);

      exportToCsvWithLoadingState(selectedCustomers);
      handleSelectionChange(SelectionType.All, false);
    },
    [customers]
  );

  const promotedBulkActions = [
    {
      content: "Export to CSV",
      onAction: () => handleExportAction(selectedResources),
      isLoading: exportToCSVIsLoading || isLoading,
      disabled: isLoading,
    },
    {
      content: "Open in new tab",
      onAction: () => handleOpenInNewTab(selectedResources),
      isLoading,
      disabled: isLoading,
    },
    {
      content: "View in Shopify",
      onAction: () => handleViewInShopify(selectedResources),
      isLoading,
      disabled: isLoading,
    },
  ];

  if (customerId) {
    return <CustomerDetails customerShopifyId={customerId} />;
  }

  return (
    <React.Fragment>
      <TablePage
        title="Customers"
        primaryAction={
          <LegacyStack vertical={isMobile} alignment="center">
            <ButtonBoldText>
              <Button
                plain
                monochrome
                removeUnderline
                icon={ExportMinor}
                loading={exportToCSVIsLoading}
                onClick={() => exportToCsvWithLoadingState()}
              >
                Export to CSV
              </Button>
            </ButtonBoldText>
            <ButtonBoldText>
              <Button
                plain
                monochrome
                removeUnderline
                icon={ExternalMinor}
                loading={isLoading}
                onClick={() => viewCustomersInShopify(activeOrg)}
              >
                View in Shopify
              </Button>
            </ButtonBoldText>
          </LegacyStack>
        }
      >
        <Layout>
          <Layout.Section>
            <LegacyStack spacing="loose" vertical>
              <LegacyCard>
                <LegacyCard.Section flush>
                  <HeaderContainer>
                    <div style={{ flex: 1 }}>
                      <TableSearch
                        key="autocomplete"
                        tableProps={tableProps}
                        selectOptions={customerSearchOptions}
                        loadData={loadCustomers}
                        setFilter={tableHandlers.setFilter}
                        setPageNumber={tableHandlers.setPageNumber}
                      />
                    </div>

                    <ButtonGroup segmented>
                      <Filter
                        columns={customerColumnsState}
                        filter={tableProps.filter}
                        setFilter={tableHandlers.setFilter}
                        filterComponent={(field, value, handleSetFilter) => (
                          <ValueSelectorForCustomersFilter
                            field={field}
                            value={value}
                            setValue={newValue => handleSetFilter("update", field, newValue)}
                          />
                        )}
                      />
                      <Columns
                        columns={customerColumnsState}
                        addToast={addToast}
                        setDisabledColumns={setCustomerColumnsState}
                      />

                      <Sorting
                        columns={customerColumnsState}
                        orderByField={tableProps.orderByField}
                        orderByValue={tableProps.orderByValue}
                        setOrderByField={tableHandlers.setOrderByField}
                        setOrderByValue={tableHandlers.setOrderByValue}
                      />
                    </ButtonGroup>
                  </HeaderContainer>

                  <Table
                    selectable
                    columns={customerColumnsState}
                    data={parseCustomersForIndexTable(customers)}
                    loading={isLoading}
                    emptyStateText="No customers"
                    resourceName={{
                      singular: "customer",
                      plural: "customers",
                    }}
                    itemCount={totalCount < pageSize ? totalCount : pageSize}
                    promotedBulkActions={promotedBulkActions.filter((x): x is any => x != null)}
                    sortable={Object.values(customerColumnsState)
                      .filter(v => !v.disabled)
                      .map(v => v.sorting)}
                    selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
                    sortColumnIndex={Object.entries(customerColumnsState).findIndex(
                      ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
                    )}
                    sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
                    onSort={(headingIndex: number, direction: "ascending" | "descending") => {
                      tableHandlers.setOrderByField(
                        Object.entries<TypedTableColumnValue>(customerColumnsState).filter(
                          ([key, value]) => !value.disabled
                        )[headingIndex][1].paginatedValue
                      );
                      tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
                    }}
                    onSelectionChange={(selectionType, toggleType, selection) => {
                      handleSelectionChange(selectionType, toggleType, selection);
                    }}
                    isRowSelected={id => isRowSelected(id, selectedResources)}
                  />
                </LegacyCard.Section>
                <LegacyCard.Section subdued>
                  <PaginationContainer>
                    <Pagination
                      hasNext={totalPages > tableProps.pageNumber}
                      hasPrevious={tableProps.pageNumber != 1}
                      label={`Showing ${tableProps.pageNumber} of ${totalPages}`}
                      onNext={() => tableHandlers.setPageNumber(p => p + 1)}
                      onPrevious={() => tableHandlers.setPageNumber(p => p - 1)}
                    />
                  </PaginationContainer>
                </LegacyCard.Section>
              </LegacyCard>
            </LegacyStack>
          </Layout.Section>
        </Layout>
      </TablePage>
    </React.Fragment>
  );
}
