import { Button, LegacyCard, Text } from "@shopify/polaris";
import { ViewMajor } from "@shopify/polaris-icons";
import { IVendorUser } from "@smartrr/shared/entities/VendorUser";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { frontEndTabOpen } from "@smartrr/shared/utils/locationUtils";
import React, { useCallback } from "react";

import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { useToast } from "../Toast/ToastProvider";

interface IViewAsCustomerProps {
  customerShopifyId: string;
  user: IVendorUser | null;
}

export const ViewAsCustomer = ({ customerShopifyId, user }: IViewAsCustomerProps) => {
  const { addToast } = useToast();

  const onViewAsCustomer = useCallback(async (shopifyId: string | undefined, originalEmail: string) => {
    if (!shopifyId) {
      addToast("Customer id doesn't exist");
      return;
    }

    typedFrontendVendorApi
      .putReq("/customer-relationship/:shopifyId/view-as-customer", {
        params: {
          shopifyId: shopifyGidToNumber(shopifyId).toString(),
        },
        reqBody: {
          email: originalEmail,
        },
      })
      .then(res => {
        if (res.type === "success") {
          frontEndTabOpen(res.body);
        }
      });
  }, []);

  return (
    <LegacyCard>
      <LegacyCard.Section>
        <React.Fragment>
          <Button icon={ViewMajor} onClick={() => onViewAsCustomer(customerShopifyId, user?.originalEmail!)}>
            View portal as customer
          </Button>

          <div style={{ marginTop: "10px", width: "213px" }}>
            <Text variant="bodyMd" color="subdued" as="p">
              After clicking this button, login with your{" "}
              <Text variant="bodyMd" color="subdued" as="span" fontWeight="bold">
                own
              </Text>{" "}
              credentials. You will then view the account portal as the customer.
            </Text>
          </div>
        </React.Fragment>
      </LegacyCard.Section>
    </LegacyCard>
  );
};
