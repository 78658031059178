import { LegacyCard, useIndexResourceState } from "@shopify/polaris";
import { IBillWithPurchStAndCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/Billing";
import { IDeserializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";
import React, { useCallback, useEffect, useState } from "react";

import { useFilterUpdate } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import {
  getBills,
  putBulkCancelBillsWithQuery,
  putBulkRetryBillsWithQuery,
} from "@vendor-app/app/_state/actionCreators/bill";
import {
  CPSTransactionHistoryTableColumnKeyType,
  CPSTransactionHistoryTableColumnType,
  parseBillsForIndexTable,
} from "@vendor-app/app/AdminRoute/AdminBillsRoute/libs";
import {
  PaginationContainer,
  checkIfBillCanBeCanceled,
  checkIfBillCanBeRetried,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";

import { cpsTransactionHistoryColumns } from "../../../AdminSubscriptionDetailsRoute/SubscriptionDetails/components/OrdersAndEventData/TransactionHistory";
import { ICustomerTransactionHistoryProps } from "../../libs";

export const CustomerTransactionHistory = ({ activeOrg, customer }: ICustomerTransactionHistoryProps) => {
  const { addToast } = useToast();

  const { Table, Pagination } = usePolarisTypedTable<CPSTransactionHistoryTableColumnKeyType>();
  const [tableProps, tableHandlers] = useTableHandlers("createdDate", "DESC", undefined, 5);

  const [paginatedBills, setPaginatedBills] = useState<IBillWithPurchStAndCustomerRelationshipPaginatedResponse>({
    data: [],
    pageNumber: tableProps.pageNumber,
    pageSize: tableProps.pageSize,
    totalCount: 0,
    totalPages: 0,
  });
  const [isLoading, setLoading] = useState(false);

  const [cpsTransactionHistoryColumnsState] =
    useState<CPSTransactionHistoryTableColumnType>(cpsTransactionHistoryColumns);

  const { data: bills, totalPages, totalCount, pageSize } = paginatedBills;

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    paginatedBills.data.map(bill => ({ id: String(bill.id) }))
  );

  const [debouncedUpdate] = useFilterUpdate(
    ({ queryParams }) => fetchPaginatedBills(queryParams),
    tableHandlers.setPageNumber
  );

  const fetchPaginatedBills = async (queryParams: IDeserializedPaginatedQuery | undefined) => {
    setLoading(true);
    try {
      const res = await getBills({ queryParams });
      if (res.type === "success") {
        setPaginatedBills(res.body);
      }

      if (res.type === "error") {
        addToast("Error fetching transaction history");
        return;
      }
    } catch {
      addToast("Error fetching transaction history");
    }

    setLoading(false);
  };

  const retryBills = useCallback(
    async (ids: string[]) => {
      setLoading(true);
      try {
        const paginatedBillsRes = await putBulkRetryBillsWithQuery({ ids, tableProps });
        if (paginatedBillsRes.type === "success") {
          addToast("Bill(s) retried");
          setPaginatedBills(paginatedBillsRes.body);
        } else {
          addToast("Error retrying bill(s)");
        }
      } catch (error) {
        console.error(error);
        addToast("Error retrying bill(s)");
      }
      setLoading(false);
    },
    [tableProps]
  );

  const cancelBills = useCallback(
    async (ids: string[]) => {
      setLoading(true);
      try {
        const paginatedBillsRes = await putBulkCancelBillsWithQuery({ ids, tableProps });
        if (paginatedBillsRes.type === "success") {
          addToast("Retry canceled");
          setPaginatedBills(paginatedBillsRes.body);
        } else {
          addToast("Error canceling bill(s)");
        }
      } catch (error) {
        console.error(error);
        addToast("Error canceling bill(s)");
      }
      setLoading(false);
    },
    [tableProps]
  );

  const promotedBulkActions = [
    checkIfBillCanBeRetried(bills, selectedResources)
      ? {
          content: "Retry",
          onAction() {
            retryBills(
              bills.filter(bill => selectedResources.includes(String(bill.id))).map(bill => bill.uniqueId)
            );
            handleSelectionChange(SelectionType.All, false);
          },
        }
      : null,
    checkIfBillCanBeCanceled(bills, selectedResources)
      ? {
          content: "Cancel retry",
          onAction() {
            cancelBills(
              bills.filter(bill => selectedResources.includes(String(bill.id))).map(bill => bill.uniqueId)
            );
            handleSelectionChange(SelectionType.All, false);
          },
        }
      : null,
  ];

  useEffect(() => {
    if (customer.email) {
      const { orderByField, orderByValue, pageNumber, pageSize } = tableProps;
      debouncedUpdate(pageNumber, pageSize, orderByField, orderByValue, false, {
        ...tableProps.filter,
        emailOrName: customer.email,
      });
    }
  }, [
    tableProps.pageNumber,
    tableProps.pageSize,
    tableProps.orderByField,
    tableProps.orderByValue,
    tableHandlers,
    customer.email,
  ]);

  return (
    <React.Fragment>
      <LegacyCard.Section flush>
        <Table
          selectable={false}
          data={parseBillsForIndexTable(bills, activeOrg)}
          loading={isLoading}
          resourceName={{
            singular: "transaction",
            plural: "transactions",
          }}
          columns={cpsTransactionHistoryColumnsState}
          itemCount={totalCount < pageSize ? totalCount : pageSize}
          sortable={Object.values(cpsTransactionHistoryColumnsState)
            .filter(v => !v.disabled)
            .map(v => v.sorting)}
          sortColumnIndex={Object.entries(cpsTransactionHistoryColumnsState).findIndex(
            ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
          )}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
          promotedBulkActions={promotedBulkActions.filter((x): x is any => x != null)}
          emptyStateText="No transaction history"
          onSort={(headingIndex: number, direction: "ascending" | "descending") => {
            tableHandlers.setOrderByField(
              Object.values(cpsTransactionHistoryColumnsState).filter(value => !value.disabled)[headingIndex]
                .paginatedValue
            );
            tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
          }}
          onSelectionChange={(selectionType, toggleType, selection) => {
            handleSelectionChange(selectionType, toggleType, selection);
          }}
          isRowSelected={id => isRowSelected(id, selectedResources)}
        />
      </LegacyCard.Section>
      <LegacyCard.Section subdued>
        <PaginationContainer>
          <Pagination
            hasNext={totalPages > tableProps.pageNumber}
            hasPrevious={tableProps.pageNumber != 1}
            label={`Showing ${tableProps.pageNumber} of ${totalPages}`}
            onNext={() => tableHandlers.setPageNumber(p => p + 1)}
            onPrevious={() => tableHandlers.setPageNumber(p => p - 1)}
          />
        </PaginationContainer>
      </LegacyCard.Section>
    </React.Fragment>
  );
};
