import { SearchDropdownOptions } from "@vendor-app/app/_sharedComponents/TableSearch/libs";

import { CustomersTableColumnType } from "..";

export const customerColumns: CustomersTableColumnType = {
  customerShopifyId: {
    name: "Customer ID",
    paginatedValue: "customerShopifyId",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  customerName: {
    name: "Customer Name",
    paginatedValue: "customerName",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  emailOrName: {
    name: "Email",
    paginatedValue: "emailOrName",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  totalOrders: {
    name: "Total orders",
    paginatedValue: "totalOrders",
    filtering: true,
    sorting: true,
    disabled: false,
  },
  totalSpent: {
    name: "Total spent",
    paginatedValue: "totalSpent",
    filtering: true,
    sorting: true,
    disabled: false,
  },
};

export const customerSearchOptions: SearchDropdownOptions = [
  { label: "Email/Name", value: "emailOrName", placeholder: "Search by email or name" },
];
