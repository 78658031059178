import { Divider, HorizontalStack, Icon, LegacyStack, Spinner, Text, VerticalStack } from "@shopify/polaris";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";

import { useIsFeatureAvailable } from "@vendor-app/app/AdminRoute/components/authorization/featureAccess";
import React, { useCallback, useEffect, useMemo } from "react";
import { LoyaltyStoreAccess } from "@vendor-app/app/AdminRoute/AdminLoyaltyRoute/store";
import { useUpdateLoyaltyPointsModalStore } from "./UpdateLoyaltyPointsModal";
import { noawait } from "@smartrr/shared/utils/noawait";
import { EditMinor } from "@shopify/polaris-icons";
import { getUsersTierFromTieredPoints } from "@smartrr/shared/interfaces/loyalty/utils";
import { CustomBadge } from "@vendor-app/app/_sharedComponents/CustomBadge";
import { isNumber } from "lodash";

export const LoyaltyPointsSection = ({ customer }: { customer: ICustomerRelationshipShallow }) => {
  const { singularUnit, pluralUnit } = LoyaltyStoreAccess.useRewardUnits();
  const loyaltyStoreActions = LoyaltyStoreAccess.useActions();
  const isLoading = LoyaltyStoreAccess.useLoading();
  const { openModal } = useUpdateLoyaltyPointsModalStore();
  const { isTierLoyaltyEnabled } = LoyaltyStoreAccess.useTiers();
  const openPointModal = useCallback(() => openModal({ customer }), [customer]);
  const isFeatureAvailable = useIsFeatureAvailable(FeatureEnum.LOYALTY);

  const showTierBadge = useMemo(() => isTierLoyaltyEnabled, [isTierLoyaltyEnabled]);

  useEffect(() => {
    noawait(async () => loyaltyStoreActions.initialize());
  }, []);

  if (isLoading) {
    return (
      <LegacyStack alignment="center" distribution="center">
        <Spinner />
      </LegacyStack>
    );
  }

  if (!isFeatureAvailable) {
    return <React.Fragment />;
  }
  return (
    <VerticalStack gap={"4"}>
      <VerticalStack gap={"3"}>
        <HorizontalStack gap={"2"} blockAlign="center">
          <Text variant="headingSm" as="h2" id="smartrr-customer-details-loyalty-points-header">
            Loyalty
          </Text>
          {showTierBadge ? <CustomerTierBadge customer={customer} /> : <React.Fragment />}
        </HorizontalStack>

        <HorizontalStack align="space-between">
          {isNumber(customer.orgPoints) ? (
            <Text variant="bodyLg" as="span" id="smartrr-customer-details-loyalty-points-amount">
              {customer.orgPoints ?? 0} {customer.orgPoints === 1 ? singularUnit : pluralUnit}
            </Text>
          ) : (
            <Text
              variant="bodyLg"
              as="span"
              color="subdued"
              id="smartrr-customer-details-loyalty-points-no-balance"
            >
              No {singularUnit} balance currently
            </Text>
          )}
          <span
            onClick={openPointModal}
            style={{ cursor: "pointer" }}
            data-testid="customer-details-loyalty-edit-points-button"
          >
            <Icon source={EditMinor} />
          </span>
        </HorizontalStack>
      </VerticalStack>
      <Divider />
    </VerticalStack>
  );
};

const CustomerTierBadge = ({ customer }: { customer: ICustomerRelationshipShallow }) => {
  const { allTiers } = LoyaltyStoreAccess.useTiers();

  const previousYearTier = customer.yearlyTieredPoints?.find(
    entry => entry.year === new Date().getFullYear() - 1
  );

  const currentYearTier = customer.yearlyTieredPoints?.find(entry => entry.year === new Date().getFullYear());

  const { previousYearTier: tier } = getUsersTierFromTieredPoints({
    orgTiers: allTiers,
    tieredPoints: currentYearTier?.points ?? customer.tieredPoints ?? 0,
    previousYearTieredPoints: previousYearTier?.points,
  });

  if (!tier) {
    return <React.Fragment />;
  }

  return (
    <CustomBadge>
      <HorizontalStack>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          data-testid="loyalty-tier-color-badge"
        >
          <rect
            x="6.625"
            y="6.625"
            width="6.75"
            height="6.75"
            rx="2.375"
            fill={tier.tierColor}
            stroke={tier.tierColor}
            strokeWidth="3"
          />
        </svg>
        {tier.tierName}
      </HorizontalStack>
    </CustomBadge>
  );
};
