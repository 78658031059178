import { Card, VerticalStack } from "@shopify/polaris";
import { BirthdaySection } from "./BirthdaySection";
import { LoyaltyPointsSection } from "./LoyaltyPointsSection";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import React from "react";
import { UpdateLoyaltyPointsModal } from "./UpdateLoyaltyPointsModal";

export const LoyaltyAndBirthdayCard = ({ customer }: { customer: ICustomerRelationshipShallow }) => {
  return (
    <React.Fragment>
      <Card>
        <VerticalStack gap={"4"}>
          <div id="smartrr-customer-details-loyalty-points-info">
            <LoyaltyPointsSection customer={customer} />
          </div>
          <BirthdaySection customer={customer} />
        </VerticalStack>
      </Card>
      <UpdateLoyaltyPointsModal />
    </React.Fragment>
  );
};
