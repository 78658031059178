import { LegacyCard, useIndexResourceState } from "@shopify/polaris";
import {
  ISmartrrOrderWithCustomerRelationship,
  ISmartrrOrderWithCustomerRelationshipPaginatedResponse,
} from "@smartrr/shared/entities/Order";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IDeserializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";
import React, { useEffect, useState } from "react";

import { useFilterUpdate } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import { getOrders } from "@vendor-app/app/_state/actionCreators/order";
import {
  CPSOrderHistoryTableColumnKeyType,
  CPSOrderHistoryTableColumnType,
  parseOrdersForIndexTable,
} from "@vendor-app/app/AdminRoute/AdminOrdersRoute/libs";
import {
  PaginationContainer,
  orderHistoryColumns,
  viewSubscriptionsByCustomer,
} from "@vendor-app/app/AdminRoute/AdminSubscriptionDetailsRoute/libs";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";

import { ICustomerOrderHistoryProps } from "../../libs";

export const CustomerOrderHistory = ({ customer, activeOrg }: ICustomerOrderHistoryProps) => {
  const { addToast } = useToast();
  const { Table, Pagination } = usePolarisTypedTable<CPSOrderHistoryTableColumnKeyType>();

  const [tableProps, tableHandlers] = useTableHandlers("orderProcessedDate", "DESC", undefined, 5);
  const [paginatedOrders, setPaginatedOrders] = useState<ISmartrrOrderWithCustomerRelationshipPaginatedResponse>({
    data: [],
    pageNumber: tableProps.pageNumber,
    pageSize: tableProps.pageSize,
    totalCount: 0,
    totalPages: 0,
  });
  const [isLoading, setLoading] = useState(false);
  const [orderHistoryColumnsState] = useState<CPSOrderHistoryTableColumnType>(orderHistoryColumns);
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    paginatedOrders.data.map(order => ({ id: String(order.id) }))
  );

  const [debouncedUpdate] = useFilterUpdate(
    ({ queryParams }) => fetchPaginatedOrders(queryParams),
    tableHandlers.setPageNumber
  );

  const { data: orders, totalPages, totalCount, pageSize } = paginatedOrders;

  const fetchPaginatedOrders = async (queryParams: IDeserializedPaginatedQuery | undefined) => {
    setLoading(true);

    try {
      const res = await getOrders({ queryParams });
      if (res.type === "success") {
        setPaginatedOrders(res.body);
      }

      if (res.type === "error") {
        addToast("Error fetching order history");
        return;
      }
    } catch {
      addToast("Error fetching order history");
    }

    setLoading(false);
  };

  const openOrdersInNewTab = (
    selectedResources: string[],
    orders: ISmartrrOrderWithCustomerRelationship[],
    activeOrg: IOrganization | null
  ) => {
    const order = orders.find(order => order.id === selectedResources[0]);

    if (order && order.custRel?.shopifyId && activeOrg) {
      return viewSubscriptionsByCustomer(order.custRel.shopifyId, activeOrg);
    }
  };

  const promotedBulkActions = [
    {
      content: "View in Shopify",
      onAction() {
        openOrdersInNewTab(selectedResources, orders, activeOrg);
        handleSelectionChange(SelectionType.All, false);
      },
    },
  ];

  useEffect(() => {
    if (customer.email) {
      const { orderByField, orderByValue, pageNumber, pageSize } = tableProps;
      debouncedUpdate(pageNumber, pageSize, orderByField, orderByValue, false, {
        ...tableProps.filter,
        email: customer.email,
      });
    }
  }, [
    tableProps.pageNumber,
    tableProps.pageSize,
    tableProps.orderByField,
    tableProps.orderByValue,
    tableHandlers,
    customer.email,
  ]);

  return (
    <React.Fragment>
      <LegacyCard.Section flush>
        <Table
          selectable
          columns={orderHistoryColumnsState}
          data={parseOrdersForIndexTable(orders, activeOrg)}
          loading={isLoading}
          emptyStateText="No order history"
          resourceName={{
            singular: "order",
            plural: "orders",
          }}
          itemCount={totalCount < pageSize ? totalCount : pageSize}
          promotedBulkActions={promotedBulkActions}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          sortable={Object.values(orderHistoryColumnsState)
            .filter(v => !v.disabled)
            .map(v => v.sorting)}
          sortColumnIndex={Object.entries(orderHistoryColumnsState).findIndex(
            ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
          )}
          sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
          onSelectionChange={(selectionType, toggleType, selection) => {
            handleSelectionChange(selectionType, toggleType, selection);
          }}
          onSort={(headingIndex: number, direction: "ascending" | "descending") => {
            tableHandlers.setOrderByField(
              Object.values(orderHistoryColumnsState).filter(value => !value.disabled)[headingIndex]
                .paginatedValue
            );
            tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
          }}
          isRowSelected={id => isRowSelected(id, selectedResources)}
        />
      </LegacyCard.Section>
      <LegacyCard.Section subdued>
        <PaginationContainer>
          <Pagination
            hasNext={totalPages > tableProps.pageNumber}
            hasPrevious={tableProps.pageNumber != 1}
            label={`Showing ${tableProps.pageNumber} of ${totalPages}`}
            onNext={() => tableHandlers.setPageNumber(p => p + 1)}
            onPrevious={() => tableHandlers.setPageNumber(p => p - 1)}
          />
        </PaginationContainer>
      </LegacyCard.Section>
    </React.Fragment>
  );
};
