import { LegacyCard, useIndexResourceState } from "@shopify/polaris";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/PurchaseState";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { useBoolean } from "@smartrr/shared/hooks/useBoolean";
import { IDeserializedPaginatedQuery } from "@smartrr/shared/utils/paginatedQuery";
import { pluralize, pluralizeForPrefix } from "@smartrr/shared/utils/pluralize";
import React, { useCallback, useEffect, useState } from "react";

import { ConfirmationWindow } from "@vendor-app/app/_sharedComponents/ConfirmationWindow";
import { SubscriptionErrorModal } from "@vendor-app/app/_sharedComponents/SubscriptionErrorModal";
import { useFilterUpdate } from "@vendor-app/app/_sharedComponents/TableSearch/libs";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  SelectionType,
  usePolarisTypedTable,
} from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";
import { useTableHandlers } from "@vendor-app/app/_sharedComponents/TypedTable/useTableHandlers";
import { getCustomerPurchaseStates } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { updateShowSetUnpauseDate } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useBulkActionErrorsReducer } from "@vendor-app/utils/handleBulkActionErrors";
import { isRowSelected } from "@vendor-app/utils/isIndexTableRowSelected";

import {
  PaginationContainer,
  getChangeSubscriptionStatusAction,
  getChangeSubscriptionStatusLabel,
  getSellingPlanGroups,
  parseSubscriptionForIndexTable,
} from "../../../AdminSubscriptionDetailsRoute/libs";
import { SetUnpauseDateModal } from "../../../AdminSubscriptionDetailsRoute/SubscriptionDetails/modals";
import { CustomerSubscriptionTableColumnKeyType, customerSubscriptionColumns } from "../../libs";
import { useCustomerDetailsActions } from "../libs/hooks/usePauseSubscriptions";

interface ICustomerSubscriptionTableProps {
  customer: ICustomerRelationshipShallow;
  activeOrg: IOrganization | null;
}

export const CustomerSubscriptionsTable = ({ customer, activeOrg }: ICustomerSubscriptionTableProps) => {
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();
  const { Table, Pagination } = usePolarisTypedTable<CustomerSubscriptionTableColumnKeyType>();
  const [tableProps, tableHandlers] = useTableHandlers("upcomingOrderDate", "ASC", undefined, 5);
  const {
    bulkActionErrorsState,
    closeModal: closeBulkActionErrorsModal,
    handleBulkActionErrors,
  } = useBulkActionErrorsReducer();

  const { onPauseSubscription, onActivateSubscription, onCancelSubscription } = useCustomerDetailsActions();

  const [isActivateConfirmationOpen, openActivateConfirmation, closeActivateConfirmation] = useBoolean(false);
  const [isCancelConfirmationOpen, openCancelConfirmation, closeCancelConfirmation] = useBoolean(false);
  const [sellingPlanGroups, setSellingPlanGroups] = useState<ISmartrrSellingPlanGroup[]>([]);

  const [paginatedPurchaseStates, setPaginatedPurchaseStates] =
    useState<IPurchaseStateWithCustomerRelationshipPaginatedResponse>({
      data: [],
      pageNumber: tableProps.pageNumber,
      pageSize: tableProps.pageSize,
      totalCount: 0,
      totalPages: 0,
    });
  const [isLoading, setLoading] = useState(false);

  const { pageNumber } = tableProps;

  const { data: purchaseStates, totalPages, totalCount } = paginatedPurchaseStates;

  const [subscriptionColumnsState] = useState(customerSubscriptionColumns);
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
    purchaseStates.map(state => ({ id: String(state.id) }))
  );

  const [debouncedUpdate] = useFilterUpdate(
    ({ queryParams }) => fetchPaginatedSubscriptions(queryParams),
    tableHandlers.setPageNumber
  );

  const openSetUnpauseDateModal = () => dispatch(updateShowSetUnpauseDate(true));

  const fetchPaginatedSubscriptions = async (queryParams: IDeserializedPaginatedQuery | undefined) => {
    setLoading(true);
    try {
      const res = await getCustomerPurchaseStates({ queryParams });
      if (res.type === "success") {
        setPaginatedPurchaseStates(res.body);
      }
      if (res.type === "error") {
        addToast("Error fetching subscriptions");
      }
    } catch {
      addToast("Error fetching subscriptions");
    }
    setLoading(false);
  };

  const fetchSellingPlans = useCallback(async (bustCache: boolean) => {
    try {
      const sellingPlanGroups = await getSellingPlanGroups(bustCache);
      if (!sellingPlanGroups) {
        addToast("Error fetching selling plan groups");
        return;
      }
      setSellingPlanGroups(sellingPlanGroups);
    } catch {
      addToast("Error fetching selling plan groups");
    }
  }, []);

  const promotedBulkActions = [
    {
      content: getChangeSubscriptionStatusLabel(purchaseStates, selectedResources),
      onAction: () =>
        getChangeSubscriptionStatusAction(
          purchaseStates,
          selectedResources,
          openActivateConfirmation,
          openSetUnpauseDateModal
        ),
      isLoading,
    },
    {
      content: pluralizeForPrefix(selectedResources.length, "Cancel subscription"),
      onAction: openCancelConfirmation,
      isLoading,
      disabled: isLoading,
      destructive: true,
    },
  ];

  useEffect(() => {
    fetchSellingPlans(false);
  }, []);

  useEffect(() => {
    if (customer.email) {
      const { orderByField, orderByValue, pageNumber, pageSize } = tableProps;
      debouncedUpdate(pageNumber, pageSize, orderByField, orderByValue, false, {
        ...tableProps.filter,
        emailOrName: customer.email,
      });
    }
  }, [
    tableProps.pageNumber,
    tableProps.pageSize,
    tableProps.orderByField,
    tableProps.orderByValue,
    tableHandlers,
    customer.email,
  ]);

  return (
    <React.Fragment>
      <LegacyCard.Section flush>
        <Table
          selectable
          loading={isLoading}
          columns={subscriptionColumnsState}
          data={parseSubscriptionForIndexTable(purchaseStates, sellingPlanGroups, activeOrg)}
          emptyStateText="No subscriptions found"
          resourceName={{
            singular: "subscription",
            plural: "subscriptions",
          }}
          itemCount={totalCount < tableProps.pageSize ? totalCount : tableProps.pageSize}
          promotedBulkActions={promotedBulkActions.filter((x): x is any => x != null)}
          sortColumnIndex={Object.entries(subscriptionColumnsState).findIndex(
            ([key, value]) => value.paginatedValue === tableProps.orderByField && !value.disabled
          )}
          sortDirection={tableProps.orderByValue === "ASC" ? "ascending" : "descending"}
          selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
          sortable={Object.values(subscriptionColumnsState)
            .filter(v => !v.disabled)
            .map(v => v.sorting)}
          onSort={(headingIndex: number, direction: "ascending" | "descending") => {
            tableHandlers.setOrderByField(
              Object.values(subscriptionColumnsState).filter(value => !value.disabled)[headingIndex]
                .paginatedValue
            );
            tableHandlers.setOrderByValue(direction === "ascending" ? "ASC" : "DESC");
          }}
          onSelectionChange={(selectionType, toggleType, selection) => {
            handleSelectionChange(selectionType, toggleType, selection);
          }}
          isRowSelected={id => isRowSelected(id, selectedResources)}
        />
      </LegacyCard.Section>
      <LegacyCard.Section subdued>
        <PaginationContainer>
          <Pagination
            hasNext={totalPages > pageNumber}
            hasPrevious={pageNumber != 1}
            label={`Showing ${pageNumber} of ${totalPages || 1}`}
            onNext={() => tableHandlers.setPageNumber(pageNumber + 1)}
            onPrevious={() => tableHandlers.setPageNumber(pageNumber - 1)}
          />
        </PaginationContainer>
      </LegacyCard.Section>
      <SetUnpauseDateModal
        title={`Pause ${pluralize(selectedResources.length, "subscription")}`}
        bannerText={`Please select a future date for ${pluralize(
          selectedResources.length,
          "subscription"
        )} to auto-resume. The customer will be notified by email one week in advance of the unpause date.`}
        onConfirm={date => {
          onPauseSubscription(
            allResourcesSelected ? "all" : selectedResources,
            {
              ...tableProps,
              filter: {
                ...tableProps.filter,
                emailOrName: customer.email,
              },
            },
            date,
            setPaginatedPurchaseStates,
            setLoading,
            handleBulkActionErrors,
            addToast
          );
          handleSelectionChange(SelectionType.All, false);
        }}
      />

      <ConfirmationWindow
        title={`Are you sure you want to activate ${selectedResources.length}${
          allResourcesSelected ? "+" : ""
        } subscription(s)?`}
        onReject={closeActivateConfirmation}
        onConfirm={() => {
          onActivateSubscription(
            allResourcesSelected ? "all" : selectedResources,
            {
              ...tableProps,
              filter: {
                ...tableProps.filter,
                emailOrName: customer.email,
              },
            },
            setPaginatedPurchaseStates,
            setLoading,
            handleBulkActionErrors,
            addToast
          );
          handleSelectionChange(SelectionType.All, false);
          closeActivateConfirmation();
        }}
        confirmationText="Yes"
        rejectionText="No"
        open={isActivateConfirmationOpen}
      >
        It might take a few minutes to process this request.
      </ConfirmationWindow>

      <ConfirmationWindow
        title={`Are you sure you want to cancel ${selectedResources.length}${
          allResourcesSelected ? "+" : ""
        } subscription(s)?`}
        onReject={closeCancelConfirmation}
        onConfirm={() => {
          onCancelSubscription(
            allResourcesSelected ? "all" : selectedResources,
            {
              ...tableProps,
              filter: {
                ...tableProps.filter,
                emailOrName: customer.email,
              },
            },
            setPaginatedPurchaseStates,
            setLoading,
            handleBulkActionErrors,
            addToast
          );
          handleSelectionChange(SelectionType.All, false);
          closeCancelConfirmation();
        }}
        confirmationText="Yes"
        rejectionText="No"
        open={isCancelConfirmationOpen}
      >
        It might take a few minutes to process this request.
      </ConfirmationWindow>

      {bulkActionErrorsState.failedSubscriptions.length && bulkActionErrorsState.isModalOpen ? (
        <SubscriptionErrorModal
          errorAction={bulkActionErrorsState.actionType}
          open={bulkActionErrorsState.isModalOpen}
          closeModal={closeBulkActionErrorsModal}
          failedPurchaseStates={bulkActionErrorsState.failedSubscriptions}
        />
      ) : null}
    </React.Fragment>
  );
};
