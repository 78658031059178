import { IPurchaseStateWithCustomerRelationshipPaginatedResponse } from "@smartrr/shared/entities/PurchaseState";
import { pluralizeForPrefix } from "@smartrr/shared/utils/pluralize";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";
import { Dispatch, useCallback } from "react";

import {
  putBulkActivateCustomerPurchaseStatesWithQuery,
  putBulkCancelCustomerPurchaseStatesWithQuery,
  putBulkPauseCustomerPurchaseStatesWithQuery,
} from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { TableSettingProps } from "@vendor-app/constants/table";
import { BulkActionErrorsReducerAction } from "@vendor-app/utils/handleBulkActionErrors";

export const useCustomerDetailsActions = () => {
  const onPauseSubscription = useCallback(
    async (
      ids: "all" | string[],
      tableProps: TableSettingProps,
      unpauseDate: Date | undefined,
      setPaginatedPurchaseStates: (
        newCustomerPurchaseStates: IPurchaseStateWithCustomerRelationshipPaginatedResponse
      ) => void,
      setLoading: (value: boolean) => void,
      handleBulkActionErrors: Dispatch<BulkActionErrorsReducerAction>,
      addToast: (content: string, error?: boolean | undefined) => void
    ) => {
      setLoading(true);
      try {
        const paginatedPurchaseStatesRes = await putBulkPauseCustomerPurchaseStatesWithQuery({
          ids,
          tableProps,
          unpauseDate,
        });
        if (paginatedPurchaseStatesRes.type === "success") {
          const failedSubscriptions = paginatedPurchaseStatesRes.body.failedSubscriptions;
          const updatedSubscriptions = paginatedPurchaseStatesRes.body.newCustomerPurchaseState;

          if (ids === "all" || ids.length > failedSubscriptions.length) {
            if (unpauseDate) {
              addToast(
                `${ids === "all" ? "All" : ids.length} subscription(s) paused. Auto-resume set for ${toViewDate(
                  unpauseDate,
                  {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }
                )}.`
              );
            }
          }

          setPaginatedPurchaseStates(updatedSubscriptions);

          if (!!failedSubscriptions.length) {
            handleBulkActionErrors({
              type: "PAUSE",
              payload: {
                failedSubscriptions,
              },
            });
          }
        } else {
          addToast(pluralizeForPrefix(ids.length, "Error pausing subscription"));
        }
      } catch {
        addToast(pluralizeForPrefix(ids.length, "Error pausing subscription"));
      }
      setLoading(false);
    },
    []
  );

  const onActivateSubscription = useCallback(
    async (
      ids: "all" | string[],
      tableProps: TableSettingProps,
      setPaginatedPurchaseStates: (
        newCustomerPurchaseStates: IPurchaseStateWithCustomerRelationshipPaginatedResponse
      ) => void,
      setLoading: (value: boolean) => void,
      handleBulkActionErrors: Dispatch<BulkActionErrorsReducerAction>,
      addToast: (content: string, error?: boolean | undefined) => void
    ) => {
      setLoading(true);
      try {
        const paginatedPurchaseStatesRes = await putBulkActivateCustomerPurchaseStatesWithQuery({
          ids,
          tableProps,
        });
        if (paginatedPurchaseStatesRes.type === "success") {
          const failedSubscriptions = paginatedPurchaseStatesRes.body.failedSubscriptions;
          const updatedSubscriptions = paginatedPurchaseStatesRes.body.newCustomerPurchaseState;

          if (ids === "all" || ids.length > failedSubscriptions.length) {
            addToast(pluralizeForPrefix(ids.length, "Activated subscription"));
          }

          setPaginatedPurchaseStates(updatedSubscriptions);

          if (failedSubscriptions.length) {
            handleBulkActionErrors({
              type: "UNPAUSE",
              payload: {
                failedSubscriptions,
              },
            });
          }
        } else {
          addToast(pluralizeForPrefix(ids.length, "Error activating subscription"));
        }
      } catch {
        addToast(pluralizeForPrefix(ids.length, "Error activating subscription"));
      }
      setLoading(false);
    },
    []
  );

  const onCancelSubscription = useCallback(
    async (
      ids: "all" | string[],
      tableProps: TableSettingProps,
      setPaginatedPurchaseStates: (
        newCustomerPurchaseStates: IPurchaseStateWithCustomerRelationshipPaginatedResponse
      ) => void,
      setLoading: (value: boolean) => void,
      handleBulkActionErrors: Dispatch<BulkActionErrorsReducerAction>,
      addToast: (content: string, error?: boolean | undefined) => void
    ) => {
      setLoading(true);
      try {
        const paginatedPurchaseStatesRes = await putBulkCancelCustomerPurchaseStatesWithQuery({
          ids,
          tableProps,
        });
        if (paginatedPurchaseStatesRes.type === "success") {
          const failedSubscriptions = paginatedPurchaseStatesRes.body.failedSubscriptions;
          const updatedSubscriptions = paginatedPurchaseStatesRes.body.newCustomerPurchaseState;

          if (ids === "all" || ids.length > failedSubscriptions.length) {
            addToast(pluralizeForPrefix(ids.length, "Canceled subscription"));
          }

          setPaginatedPurchaseStates(updatedSubscriptions);

          if (failedSubscriptions.length) {
            handleBulkActionErrors({
              type: "CANCEL",
              payload: {
                failedSubscriptions,
              },
            });
          }
        } else {
          addToast(pluralizeForPrefix(ids.length, "Error canceling subscription"));
        }
      } catch {
        addToast(pluralizeForPrefix(ids.length, "Error canceling subscription"));
      }
      setLoading(false);
    },
    []
  );

  return {
    onPauseSubscription,
    onActivateSubscription,
    onCancelSubscription,
  };
};
