import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { IOrganization } from "@smartrr/shared/entities/Organization";

import { TypedTableColumnValue } from "@vendor-app/app/_sharedComponents/TypedTable/usePolarisTypedTable";

export type CustomersTableColumnKeyType =
  | "customerShopifyId"
  | "customerName"
  | "emailOrName"
  | "totalOrders"
  | "totalSpent";

export type CustomersTableColumnType = {
  [key in CustomersTableColumnKeyType]: TypedTableColumnValue;
};

export type CustomersParsedDataType = {
  [key in CustomersTableColumnKeyType]: any;
};

export interface ICustomerCSV {
  "Customer ID": string | number | undefined;
  "Customer name": string | undefined;
  Email: string | undefined;
  "Total orders": string | number | undefined;
  "Total spent": string | number | undefined;
}
export type CustomerSubscriptionTableColumnKeyType =
  | "shopifyId"
  | "status"
  | "upcomingOrderDate"
  | "estimatedTotal"
  | "items"
  | "createdDate"
  | "sellingPlanId";

export type CustomerSubscriptionParsedDataType = {
  [key in CustomerSubscriptionTableColumnKeyType]: any;
};

export interface ICustomerOrderHistoryProps {
  customer: ICustomerRelationshipShallow;
  activeOrg: IOrganization | null;
}

export interface ICustomerTransactionHistoryProps {
  customer: ICustomerRelationshipShallow;
  activeOrg: IOrganization | null;
}

export interface ICustomerDetailsProps {
  customerShopifyId: string;
}

export enum CustomerTablesTabs {
  "Subscriptions",
  "Orders",
  "Transactions",
}
