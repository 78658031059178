import React from "react";
import styled from "styled-components";

const CustomerBadgeWrapper = styled.div`
  --pc-badge-horizontal-padding: var(--p-space-200);
  --pc-badge-vertical-padding: var(--p-space-050);

  display: inline-flex;
  align-items: center;

  padding: 2px 8px;
  background-color: var(--p-color-bg-fill-transparent-secondary);
  border-radius: var(--p-border-radius-200);
  color: var(--p-color-text-secondary);
  font-weight: var(--p-font-weight-medium);
`;

interface CustomBadgeProps {
  /** The content to display inside the badge. */
  children?: JSX.Element;
}

export const CustomBadge = ({ children, ...props }: CustomBadgeProps) => {
  return <CustomerBadgeWrapper {...props}>{children}</CustomerBadgeWrapper>;
};
