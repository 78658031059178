import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";

import { getCustomers } from "@vendor-app/app/_state/actionCreators/customers";
import { ICustomerCSV } from "@vendor-app/app/AdminRoute/AdminCustomersRoute/libs";

import { getQueryObj } from "./getQueryObject";
import { mapArrayEntityWithDelay } from "./mapArrayEntityWithDelay";

export interface IExportCSVFilters {
  startDate?: string;
  endDate?: string;
  filterIn?: { [key: string]: string[] };
  filterLike?: { [key: string]: string };
  orderByField?: string;
}

export async function exportCustomersToCsvRows(filters?: IExportCSVFilters): Promise<ICustomerCSV[]> {
  const startDate = filters?.startDate;
  const endDate = filters?.endDate;

  const orderByField = filters?.orderByField;
  const filterIn = filters?.filterIn;
  const filterLike = filters?.filterLike;

  const allData: ICustomerRelationshipShallow[] = [];
  let pageNumber = 0;
  let totalPages = 1;

  while (pageNumber < totalPages) {
    const page = await getCustomers(
      getQueryObj(pageNumber, startDate, endDate, filterIn, filterLike, orderByField)
    );

    if (!page) {
      throw new Error("Unknown error");
    }

    if (page.type === "error") {
      throw new Error(page.message);
    }

    allData.push(...page.body.data);
    pageNumber = page.body.pageNumber;
    totalPages = page.body.totalPages;
    pageNumber++;
  }

  return await mapArrayEntityWithDelay<ICustomerRelationshipShallow, ICustomerCSV>({
    items: allData,
    chunkSize: 250,
    waitInterval: 500,
    filterFunction: parseCustomerForCSV,
  });
}

export function parseCustomerForCSV(customer: ICustomerRelationshipShallow): ICustomerCSV {
  return {
    "Customer ID": `#${shopifyGidToNumber(customer.shopifyId ?? "")}`,
    "Customer name": `${customer.firstName} ${customer.lastName}`,
    Email: customer.email,
    "Total orders": customer.orderCount,
    "Total spent": customer.totalSpent,
  };
}
