import { useReducer } from "react";

import {
  FailedPurchaseStateAction,
  IFailedSubscriptionWithReason,
} from "@vendor-app/app/_sharedComponents/SubscriptionErrorModal";

interface IBulkActionErrorsState {
  actionType: FailedPurchaseStateAction;
  failedSubscriptions: IFailedSubscriptionWithReason[];
  isModalOpen: boolean;
}

export type BulkActionErrorsReducerAction =
  | {
      type: "PAUSE" | "UNPAUSE" | "CANCEL" | "SET_NEXT_ORDER_DATE";
      payload: { failedSubscriptions: IFailedSubscriptionWithReason[] };
    }
  | {
      type: "CLOSE_MODAL";
    };

const initState: IBulkActionErrorsState = {
  actionType: undefined,
  failedSubscriptions: [],
  isModalOpen: false,
};

const reducer = (
  state: IBulkActionErrorsState,
  action: BulkActionErrorsReducerAction
): IBulkActionErrorsState => {
  switch (action.type) {
    case "PAUSE": {
      return {
        ...state,
        actionType: "pause",
        failedSubscriptions: action.payload.failedSubscriptions,
        isModalOpen: true,
      };
    }
    case "UNPAUSE": {
      return {
        ...state,
        actionType: "unpause",
        failedSubscriptions: action.payload.failedSubscriptions,
        isModalOpen: true,
      };
    }
    case "CANCEL": {
      return {
        ...state,
        actionType: "cancel",
        failedSubscriptions: action.payload.failedSubscriptions,
        isModalOpen: true,
      };
    }
    case "SET_NEXT_ORDER_DATE": {
      return {
        ...state,
        actionType: "set next date for",
        failedSubscriptions: action.payload.failedSubscriptions,
        isModalOpen: true,
      };
    }
    case "CLOSE_MODAL": {
      return { ...state, isModalOpen: false, actionType: undefined, failedSubscriptions: [] };
    }
    default: {
      return state;
    }
  }
};

export const useBulkActionErrorsReducer = () => {
  const [bulkActionErrorsState, dispatch] = useReducer(reducer, initState);

  const closeModal = () => {
    dispatch({ type: "CLOSE_MODAL" });
  };

  return { bulkActionErrorsState, closeModal, handleBulkActionErrors: dispatch };
};
