import { sortTiersByRank } from "../../utils/sortBy";
import { LoyaltyApi } from "./api";

/**
 * Determines a user's loyalty tier based on their current and previous year's earned points.
 *
 * orgTiers - List of orgs tiers
 * tieredPoints - The points earned by customer in the current year.
 * previousYearTieredPoints - The points earned by customer in the previous year.
 *
 * @returns
 *  - `previousYearTier`: The tier determined based on the greater number of points between last year and current year
 *  - `currentYearTier`: The tier based on points earned in the current year.
 *  - `nextTier`: The next tier in rank above the `currentYearTier`, or `null` if the user is at the highest tier.
 */
export const getUsersTierFromTieredPoints = ({
  orgTiers,
  tieredPoints,
  previousYearTieredPoints,
}: {
  orgTiers: LoyaltyApi.Tier.Type[];
  tieredPoints: number | null | undefined;
  previousYearTieredPoints?: number;
}): {
  previousYearTier: LoyaltyApi.Tier.Type | null;
  currentYearTier: LoyaltyApi.Tier.Type | null;
  nextTier: LoyaltyApi.Tier.Type | null;
} => {
  if (!orgTiers.length) {
    return { previousYearTier: null, currentYearTier: null, nextTier: null };
  }
  const sortedTiers = sortTiersByRank(orgTiers);

  if (!tieredPoints) {
    tieredPoints = 0;
  }

  // Current tier is determined by previous year's point amount or current year's point amount, whichever is greater
  const pointsToReference =
    previousYearTieredPoints && previousYearTieredPoints > tieredPoints ? previousYearTieredPoints : tieredPoints;

  let previousYearTier = sortedTiers[0];
  let currentYearTier = sortedTiers[0];

  for (const tier of sortedTiers) {
    if (pointsToReference >= tier.minimumPoints) {
      previousYearTier = tier;
    }

    if (tieredPoints >= tier.minimumPoints) {
      currentYearTier = tier;
    }
  }

  const currentYearTierIndex = sortedTiers.indexOf(currentYearTier);

  return {
    previousYearTier,
    currentYearTier,
    nextTier: sortedTiers[currentYearTierIndex + 1] ?? null,
  };
};

// TODO: Deprecate function in favor of one above
export function getUserPreviousAndCurrentTier(
  orgTiers: LoyaltyApi.Tier.Type[],
  tierPoints: number | null | undefined
): { currentTier: LoyaltyApi.Tier.Type | null; previousTier: LoyaltyApi.Tier.Type | null } {
  if (!orgTiers.length) {
    return { currentTier: null, previousTier: null };
  }

  const sortedTiers = sortTiersByRank(orgTiers);

  if (tierPoints == null) {
    return { currentTier: sortedTiers[0], previousTier: null };
  }

  let currentTierIndex = 0;
  for (let i = 0; i < sortedTiers.length; i++) {
    if (tierPoints >= sortedTiers[i].minimumPoints) {
      currentTierIndex = i;
    }
  }

  const currentTier = sortedTiers[currentTierIndex];
  const previousTier = sortedTiers[currentTierIndex - 1] ?? null;

  return { currentTier, previousTier };
}
